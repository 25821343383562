.myTd {
    border: 1px solid;
}


.mysuccess {
    background-color: #DFF0D8
}

.myinfo {
    background-color: #D9EDF7
}

.mywarning{
    background-color: #FCF8E3
}
.sum{
    background-color:#e1e8e2 
}
.sumcat{
    background-color:#f5ebeb 
}

.mysuccess-dark {
    background-color: #345d24
}
.react-tagsinput-tag {
    background-color: skyblue !important;
    border-radius: 2px;
    border: 5px solid skyblue !important;
    color: white !important;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}
.react-tagsinput--focused {
    border-color:skyblue !important
}
.table-default thead{
    background-color: rgb(224 231 255/var(--tw-bg-opacity));
}
/* .select .select__control{
    display: none;
} */

.table-default tbody>tr>td {
    padding: 0.3rem 1.0rem !important;
}

.container{
    max-width:100%
}
.social-login-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    flex-wrap: wrap; 
}

.google-button,
.facebook-button {
    flex: 1;
    min-width: calc(50% - 1rem);
}

.google-button {
    background-color: #db4437;
    color: white;
    margin-right: 0.5rem;
}

.facebook-button {
    background-color: #3b5998;
    color: white;
    margin-left: 0.5rem;
}

.google-button:hover {
    background-color: #c23321;
}

.facebook-button:hover {
    background-color: #2d4373;
}

.login-logo-container {
    display: none; 
    text-align: center; 
}

@media (max-width: 600px) {
    .social-login-buttons {
        flex-direction: column;
        align-items: center; 
    }

    .google-button,
    .facebook-button {
        margin: 0.5rem 0;
        width: 100%;
    }

    .login-logo-container {
        display: block; 
        text-align: center; 
        margin-bottom: 2rem; 
    }

    .login-logo-container img {
        display: inline-block; /* Ensure the image is treated as an inline element for centering */
    }
}


.form-sub-label{
    color: darkred;
    text-align: left;
    margin-top: -.5rem;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}